<template>
  <div class="references content-text text-size-small">
    <div class="line" />
    <div class="lists">
      <div
        v-if="terminologyList && terminologyList.length !== 0"
        class="terminology-list"
      >
        <span v-for="term in terminologyList" :key="term.id" :id="term.id">
          {{ term.text }}
        </span>
      </div>
      <ol class="references-list">
        <li v-for="link in list" :key="link.id" :id="link.id">
          {{ link.text }}
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReferencesList',
  props: {
    list: Array,
    terminologyList: Array
  }
}
</script>

<style scoped>
.lists {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.references {
  margin-top: 9rem;
  display: flex;
  flex-direction: column;
  gap: 32px;
  word-wrap: break-word;
}
.line {
  width: 25%;
  height: 1px;
  background-color: #000000;
}
.references-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: 600;
  padding: 0px;
  padding-left: 1.5rem;
  margin-bottom: 0px;
}
.terminology-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: 600;
}

@media (min-width: 320px) and (max-width: 767px) {
  .references-list,
  .terminology-list {
    font-size: 12px;
  }

  .terminology-list {
    padding-left: 10px;
  }
  .line {
    margin-left: 10px;
  }
}
</style>
