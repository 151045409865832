<template>
  <div class="content">
    <div class="main-screen-section inside screen3"></div>

    <div class="container inside-page content-section">
      <h1>
        Клинические проявления мигрени<ReferenceLink text="1-2" href="#ref1" />
      </h1>
      <div class="content-text text-size-big mb40">
        <p>
          Мигрень — это сложный процесс в головном мозге, который может длиться
          от <br />
          4 часов до 3 дней. Выделяют четыре различные фазы:
        </p>
      </div>

      <table class="manifestations">
        <thead>
          <tr>
            <td>Продром</td>
            <td>Аура</td>
            <td>Головная боль</td>
            <td>Постдром</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>Сталкивается > 80% больных</p>
              <p>Начинается за 1-24 часа до головной боли</p>
            </td>
            <td>
              <p>Сталкивается 25% больных</p>
              <p>5-60 минут</p>
            </td>
            <td>
              <p>Сталиваются все больные</p>
              <p>от 4 часов до 3 дней</p>
            </td>
            <td>
              <p>Сталкивается 70% больных</p>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <div class="sub-title">Симптомы</div>
            </td>
          </tr>
          <tr>
            <td>
              <p>Тошнота;</p>
              <p>Рвота;</p>
              <p>Зевота;</p>
              <p>Озноб;</p>
              <p>Диарея;</p>
              <p>Потеря аппетита;</p>
              <p>Усталость;</p>
              <p>Дурнота;</p>
              <p>Депрессия;</p>
              <p>Раздражительность;</p>
              <p>Задержка жидкости;</p>
              <p>Повышенное мочеиспускание;</p>
              <p>Заторможенность;</p>
              <p>
                Чувствительность <br />
                к свету и звуку;
              </p>
              <p>Повышенная утомляемость;</p>
              <p>Гиперактивность;</p>
              <p>Мышечно-скелетные симптомы.</p>
            </td>
            <td>
              <p>Мерцающая скотома;</p>
              <p>Онемение;</p>
              <p>Покалывание;</p>
              <p>Слабость;</p>
              <p>Временный паралич;</p>
              <p>Головокружение;</p>
              <p>Временная утрата способности говорить, читать или писать.</p>
            </td>
            <td>
              <p>Интенсивная пульсирующая односторонняя головная боль;</p>
              <p>Тошнота;</p>
              <p>Рвота;</p>
              <p>Фонофобия;</p>
              <p>Фотофобия.</p>
            </td>
            <td>
              <p>Ощущение «похмелья»;</p>
              <p>Слабость;</p>
              <p>Истощение;</p>
              <p>Когнитивные нарушения;</p>
              <p>Перепады настроения;</p>
              <p>Симптомы ЖКТ;</p>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="content-text text-size-middle mb50">
        <p>
          У пациентов не всегда наблюдаются все 4 фазы во время каждого
          приступа, также фазы могут перекликаться между собой. Все 4 фазы
          усиливают дистресс и усугубляют степень инвалидизации пациента.
        </p>
      </div>

      <h2 class="mb20">
        Классификация мигрени<ReferenceLink text="3" href="#ref3" />
      </h2>
      <div class="content-text text-size-middle migraine-types">
        <h4>
          Cогласно Международной классификации головной боли 3-го пересмотра
          (МКГБ-3):
        </h4>
        <div class="migraine-type-list">
          <span>1. Мигрень</span>
          <span>1.1. Мигрень без ауры</span>
          <span>1.2. Мигрень с аурой</span>
          <span>1.2.1. Мигрень с типичной аурой</span>
          <span>1.2.1.1. Типичная аура с ГБ</span>
          <span>1.2.1.2. Типичная аура без ГБ</span>
          <span>1.2.2. Мигрень со стволовой аурой</span>
          <span>1.2.3. Гемиплегическая мигрень</span>
          <span>1.2.3.1. Семейная гемиплегическая мигрень</span>
          <span>1.2.3.1.1 Семейная гемиплегическая мигрень 1 типа</span>
          <span>1.2.3.1.2 Семейная гемиплегическая мигрень 2 типа</span>
          <span>1.2.3.1.3 Семейная гемиплегическая мигрень 3 типа</span>
          <span>
            1.2.3.1.4 Семейная гемиплегическая мигрень (с мутациями в других
            локусах)
          </span>
          <span>1.2.3.2. Спорадическая гемиплегическая мигрень</span>
          <span>1.2.4. Ретинальная мигрень</span>
          <span>1.3. Хроническая мигрень</span>
          <span>1.4. Осложнения мигрени</span>
          <span>1.4.1. Мигренозный статус</span>
          <span>1.4.2. Персистирующая аура без инфаркта</span>
          <span>1.4.3. Мигренозный инфаркт</span>
          <span>1.4.4. Мигренозная аура- триггер эпилептического припадка</span>
          <span>1.5. Возможная мигрень</span>
          <span>1.5.1. Возможная мигрень без ауры</span>
          <span>1.5.2. Возможная мигрень с аурой</span>
          <span>
            1.6. Эпизодические синдромы, которые могут сочетаться с мигренью
          </span>
          <span>1.6.1. Повторяющиеся желудочно-кишечные нарушения</span>
          <span>1.6.1.1. Синдром циклических рвот</span>
          <span>1.6.1.2. Абдоминальная мигрень</span>
          <span>1.6.2. Доброкачественное пароксизмальное головокружение</span>
          <span>1.6.3. Доброкачественный пароксизмальный тортиколис</span>
        </div>
      </div>
      <div class="content-text text-size-middle migraine-main-types">
        <h3>Основные типы мигрени: </h3>
        <div class="main-types-list">
          <span>
            <i class="spacer" />
            1.1 <strong>Мигрень без ауры</strong> представляет клинический
            синдром, характеризующийся головной болью со специфическими
            признаками и сопровождающими симптомами;
          </span>
          <span>
            <i class="spacer" />1.2 <strong>Мигрень с аурой</strong> в первую
            очередь характеризуется преходящими очаговыми неврологическими
            симптомами, которые обычно предшествуют или иногда сопровождают
            головную боль. У некоторых пациентов также отмечается продромальная
            фаза, возникающая за несколько часов или дней до головной боли,
            и/или постдромальная фаза после окончания головной боли.
            Продромальные и постдромальные симптомы включают гиперактивность или
            снижение активности, депрессию, тягу к определенной пище,
            повторяющуюся зевоту, усталость, напряжение и/или боль в шее. Если у
            пациента выполняются диагностические критерии более одного типа,
            подтипа и подформы мигрени, все они должны быть вынесены в диагноз и
            кодированы. Например, у пациента с частыми приступами с аурой и
            иногда возникающими приступами без ауры, диагноз должен быть
            кодирован как Мигрень с аурой и Мигрень без ауры.
          </span>
          <span>
            <i class="spacer" />1.3 <strong>Хроническая мигрень</strong> -
            головная боль, возникающая в течение 15 или более дней в месяц на
            протяжении более трех месяцев, которая имеет признаки мигренозной
            головной боли по крайней мере в течение восьми дней в месяц.
          </span>
        </div>
      </div>
      <ReferencesList :list="referencesList" />
    </div>
  </div>
</template>

<script>
import ReferenceLink from '../components/TevaReference/ReferenceLink.vue'
import ReferencesList from '../components/TevaReference/ReferencesList.vue'

export default {
  name: 'ClinicalManifestations',
  components: {
    ReferenceLink,
    ReferencesList
  },
  metaInfo: {
    title: 'Клинические проявления',
    meta: [
      {
        name: 'description',
        content:
          'Головные боли одни из распространенных расстройств нервной системы и существенно нарушают качество жизни и трудоспособность'
      }
    ]
  },
  data() {
    return {
      referencesList: [
        {
          id: 'ref1',
          text: 'Dodick DW. Migraine. Lancet. 2018 Mar 31;391(10127):1315-1330. doi: 10.1016/S0140-6736(18)30478-1. Epub 2018 Mar 6. PMID: 29523342.'
        },
        {
          id: 'ref2',
          text: 'Peres MFP, Mercante JPP, Tobo PR, Kamei H, Bigal ME. Anxiety and depression symptoms and migraine: a symptom-based approach research. J Headache Pain. 2017 Dec;18(1):37'
        },
        {
          id: 'ref3',
          text: 'КЛИНИЧЕСКИЙ ПРОТОКОЛ ДИАГНОСТИКИ И ЛЕЧЕНИЯ «МИГРЕНЬ У ВЗРОСЛЫХ». Одобрено ОКМУМЗ РК от «07» сентября 2023 года. Протокол №188'
        }
      ]
    }
  }
}
</script>

<style scoped>
.migraine-types {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: 600;
}
.migraine-types h4 {
  font-size: 1.5rem;
  letter-spacing: normal;
}
.migraine-type-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 60px;
}

.main-types-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.main-types-list .spacer {
  margin-left: 12px;
}

@media (min-width: 320px) and (max-width: 767px) {
  .content-text p br {
    display: none;
  }
  .migraine-types {
    font-size: 1rem;
  }
  .migraine-types h4 {
    font-size: 1rem;
  }
  .migraine-type-list {
    margin-bottom: 40px;
  }
}
</style>
