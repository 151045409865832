<template>
  <sup>
    <a :href="href">{{ text }}</a>
  </sup>
</template>

<script>
export default {
  name: 'ReferenceLink',
  props: {
    href: String,
    text: String
  }
}
</script>

<style scoped>
sup {
  font-size: 55%;
  font-weight: 600;
}
sup a {
  color: inherit;
}
</style>
